import { ReactNode, useMemo } from 'react';

import { useIsDesktopViewport } from '@/hooks/useIsMobileViewport.ts';
import { RecordingControlsState } from '@/modules/recording/components/RecordingControls/RecordingControls.tsx';

import { RecordingLayout } from '../../layouts/RecordingLayout';

import { Content } from './RecordingStep.styles';

export interface RecordingStepProps {
  state: RecordingControlsState;
  onBack?: () => void;
  Banner?: ReactNode;
  RecordingIndicator: ReactNode;
  RecordingPrompt: ReactNode;
  RecordingControls: ReactNode;
  RecordingPreview: ReactNode;
}

export function RecordingStep({
  state,
  onBack,
  Banner,
  RecordingIndicator,
  RecordingPrompt,
  RecordingControls,
  RecordingPreview,
}: RecordingStepProps) {
  const isDesktop = useIsDesktopViewport();

  const headerState = useMemo(() => {
    switch (state.type) {
      case 'ready':
        return { title: "You're ready to record", step: 'setup', onBack } as const;
      case 'review':
        return { title: 'Review and submit', step: 'review', onBack } as const;
      default:
        return { title: null, step: 'recording' } as const;
    }
  }, [onBack, state.type]);

  return (
    <RecordingLayout.Root
      showLogo={isDesktop}
      dark={state.type === 'recording'}
      Header={
        <>
          {Banner}
          <RecordingLayout.Header
            step={headerState.step}
            title={headerState.title}
            hideSeparator={state.type === 'recording'}
            transparentStepper={state.type === 'recording'}
            onBack={headerState.onBack}
          >
            {state.type !== 'review' ? RecordingIndicator : null}
          </RecordingLayout.Header>
        </>
      }
      Footer={
        <RecordingLayout.Footer hideSeparator={state.type === 'recording'}>{RecordingControls}</RecordingLayout.Footer>
      }
    >
      <Content>{state.type === 'review' ? RecordingPreview : RecordingPrompt}</Content>
    </RecordingLayout.Root>
  );
}
