import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

export const RecordingPromptBodyWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const RecordingModeBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);
`;

export const RecordingModeIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-2xl);
  padding: var(--spacing-md) 0;
`;

export const RecordingModeIcon = styled(FontAwesomeIcon)`
  color: var(--primary);
`;
