import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Backdrop = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  justify-content: space-between;
  padding: var(--spacing-3xl) 0;

  transition: background 500ms ease;
  background: rgba(20, 31, 31, 0.95);

  &[data-begin='true'] {
    background: rgba(34, 118, 70, 0.95);
  }
`;

export const Countdown = styled(motion.span)`
  color: #fff;
  text-align: center;
  font-family: var(--font-sans);
  font-size: 120px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &[data-done='true'] {
    font-size: 45px;
  }
`;

export const SkipButton = styled.button`
  padding: var(--spacing-sm) var(--spacing-2xl);
`;
