import { faWarning } from '@fortawesome/pro-solid-svg-icons';

import logomarkSrc from '@/@assets/logomark.svg';
import { RMText } from '@/components/RMText/RMText';

import { RecordingLayout } from '../../layouts/RecordingLayout';

import { Button, LeftCard, Logomark, PageContent, RightCard, WarningIcon } from './RecordUploading.styles';

export interface RecordUploadingProps {
  status: 'in-progress' | 'failed';
  progress: number;
  onGoToRecover: () => void;
}

export function RecordUploading({ status, progress, onGoToRecover }: RecordUploadingProps) {
  return (
    <RecordingLayout.Root Header={<RecordingLayout.Header step="hidden" hideSeparator />}>
      <PageContent>
        <LeftCard>
          {status === 'in-progress' ? <Logomark src={logomarkSrc} alt="Logomark" /> : <WarningIcon icon={faWarning} />}
          <RMText type="sans" size="m" color="on-surface-secondary" bold>
            {status === 'in-progress' ? 'Uploading...' : 'Upload error'}
          </RMText>
          {status === 'in-progress' && (
            <RMText type="sans" size="m" color="on-surface-secondary" bold>
              {(progress * 100).toFixed(0)}%
            </RMText>
          )}
        </LeftCard>
        <RightCard>
          <RMText type="serif" size="l" color="on-surface-primary">
            {status === 'in-progress' ? 'Almost done...' : 'We’ve experienced an error'}
          </RMText>
          <RMText type="sans" size="s" color="on-surface-primary">
            {status === 'in-progress'
              ? 'Keep this browser tab open while we finish uploading your recording.'
              : 'We ran into an issue while uploading your recording to Remento. Tap the button below to recover it.'}
          </RMText>
          {status === 'failed' && (
            <Button background="primary" autoLoading onClick={onGoToRecover}>
              Recover recording
            </Button>
          )}
        </RightCard>
      </PageContent>
    </RecordingLayout.Root>
  );
}
