import { InterviewManagerRecording, RecordingType } from '@/modules/conversation-recorder/interview';

import { RecordingPlayback } from '../RecordingPlayback';

import { StyledRecordingPreview, VideoWrapper } from './RecordingPreview.styles';

export interface RecordingPreviewProps {
  recordingType: RecordingType;
  recordings: InterviewManagerRecording[];
}

export function RecordingPreview({ recordingType, recordings }: RecordingPreviewProps) {
  return (
    <RecordingPlayback.Root
      recordings={recordings}
      // To show the video thumbnail, we need to at least load the first frame.
      initialTime={recordingType === 'video' ? 0.5 : undefined}
      stream={false}
    >
      <StyledRecordingPreview data-recording-type={recordingType}>
        <VideoWrapper hidden={recordingType === 'audio'}>
          <RecordingPlayback.Video />
        </VideoWrapper>
        <RecordingPlayback.Controls />
      </StyledRecordingPreview>
    </RecordingPlayback.Root>
  );
}
