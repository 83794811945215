import { useState } from 'react';
import { faRecordVinyl } from '@fortawesome/pro-regular-svg-icons';
import { faPaperPlaneTop, faPause, faStop, faTrashCan } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { useIsDesktopViewport } from '@/hooks/useIsMobileViewport';
import { RecordingType } from '@/modules/conversation-recorder/interview';

import { RecordingCamera } from '../RecordingCamera/RecordingCamera';
import { RecorderCountdown } from '../RecordingCountdown/RecordingCountdown';

import { Pip, StartButtonWrapper, StyledRecordingControls } from './RecordingControls.styles';

export type RecordingControlsAction =
  | 'start-recording'
  | 'stop-recording'
  | 'end-recording'
  | 'rewind-prompt'
  | 'confirm-recording'
  | 'close-confirm';

export interface RecordingControlsReadyState {
  type: 'ready';
  isFirstTake: boolean;
}

export interface RecordingControlsPausedState {
  type: 'paused';
  isRecordingTooShort: boolean;
  canContinueRecording: boolean;
}

export interface RecordingControlsRecordingState {
  type: 'recording';
}

export interface RecordingControlsReviewState {
  type: 'review';
  canContinueRecording: boolean;
  isRetakeConfirmationOpen: boolean;
}

export interface RecordingControlsFinishedState {
  type: 'finished';
}

export type RecordingControlsState =
  | RecordingControlsReadyState
  | RecordingControlsPausedState
  | RecordingControlsRecordingState
  | RecordingControlsReviewState
  | RecordingControlsFinishedState;

export interface RecordingControlsProp {
  state: RecordingControlsState;
  mediaStream: MediaStream | null;
  recordingType: RecordingType;
  onAction: (action: RecordingControlsAction) => void | Promise<void>;
}

export function RecordingControls({ state, recordingType, mediaStream, onAction }: RecordingControlsProp) {
  const isDesktop = useIsDesktopViewport();

  // States
  const [countdownVisible, setCountdownVisible] = useState(false);
  const [isEnding, setIsEnding] = useState(false);

  // Callbacks
  const handleStartCountdown = () => {
    setCountdownVisible(true);
  };

  const handleStartRecording = async () => {
    await onAction('start-recording');
    setCountdownVisible(false);
  };

  const handleStopRecording = async () => {
    await onAction('stop-recording');
  };

  // Lottie animation
  // const animationRef = useRef<AnimationItem | null>(null);
  // const lottieRef = useCallback((node: HTMLDivElement | null) => {
  //   if (node === null) {
  //     if (animationRef.current) {
  //       animationRef.current.destroy();
  //       animationRef.current = null;
  //     }
  //     return;
  //   }
  //
  //   animationRef.current = lottie.loadAnimation({
  //     container: node,
  //     renderer: 'svg',
  //     loop: true,
  //     autoplay: true,
  //     animationData: ArrowDownLottieAnimation,
  //   });
  // }, []);

  const disabled =
    (state.type === 'paused' && state.isRecordingTooShort) ||
    (state.type === 'review' && state.isRetakeConfirmationOpen);

  return (
    <StyledRecordingControls disabled={disabled}>
      {state.type === 'ready' && !countdownVisible && (
        <>
          {recordingType === 'video' && (
            <Pip>
              <RecordingCamera mediaStream={mediaStream} />
            </Pip>
          )}

          <StartButtonWrapper>
            <RMButton
              background="affirmative"
              leftIcon={state.type === 'ready' ? faRecordVinyl : null}
              fullWidth
              size={isDesktop ? 'massive' : 'medium'}
              minWidth="none"
              onClick={handleStartCountdown}
            >
              Start recording
            </RMButton>
            {/*{state.type === 'ready' && <StartArrowLottie data-video={recordingType === 'video'} ref={lottieRef} />}*/}
          </StartButtonWrapper>
        </>
      )}

      {state.type === 'recording' && (
        <>
          {recordingType === 'video' && (
            <Pip>
              <RecordingCamera mediaStream={mediaStream} />
            </Pip>
          )}

          <RMButton
            fullWidth
            size={isDesktop ? 'massive' : 'medium'}
            minWidth="none"
            autoLoading
            leftIcon={faPause}
            onClick={handleStopRecording}
          >
            Pause
          </RMButton>
          <RMButton
            fullWidth
            size={isDesktop ? 'massive' : 'medium'}
            minWidth="none"
            autoLoading
            background="primary"
            leftIcon={faStop}
            onClick={handleStopRecording}
          >
            Stop
          </RMButton>
        </>
      )}

      {state.type === 'paused' && !state.isRecordingTooShort && (
        <>
          <RMButton
            fullWidth
            size={isDesktop ? 'massive' : 'medium'}
            minWidth="none"
            disabled={!state.canContinueRecording}
            autoLoading
            onClick={handleStartCountdown}
          >
            Resume
          </RMButton>

          <RMButton
            background="primary"
            fullWidth
            size={isDesktop ? 'massive' : 'medium'}
            minWidth="none"
            autoLoading
            onClick={async () => {
              onAction('confirm-recording');
            }}
          >
            Go to review
          </RMButton>
        </>
      )}

      {state.type === 'review' && (
        <>
          <RMButton
            disabled={isEnding}
            fullWidth
            size={isDesktop ? 'massive' : 'medium'}
            minWidth="none"
            autoLoading
            leftIcon={faTrashCan}
            onClick={() => {
              onAction('rewind-prompt');
            }}
          >
            Start over
          </RMButton>
          <RMButton
            disabled={isEnding}
            fullWidth
            size={isDesktop ? 'massive' : 'medium'}
            minWidth="none"
            autoLoading
            background="primary"
            leftIcon={faPaperPlaneTop}
            onClick={async () => {
              try {
                setIsEnding(true);
                await onAction('end-recording');
              } finally {
                setIsEnding(false);
              }
            }}
          >
            Submit
          </RMButton>
        </>
      )}

      {countdownVisible && <RecorderCountdown onStartRecording={handleStartRecording} />}
    </StyledRecordingControls>
  );
}
