import { styled } from '@linaria/react';

import { LottieAnimation } from '@/components/LottieAnimation';
import { RMText } from '@/components/RMText/RMText';

export const Content = styled.div`
  max-width: 33.25rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: var(--spacing-2xl);
  width: 100%;

  &[data-loading='true'] {
    height: 100%;
  }

  body[data-mobile='false'] & {
    height: 100%;
    padding-bottom: var(--spacing-5xl);
  }

  @media (max-height: 350px) {
    height: fit-content !important;
  }
`;

export const LoadingContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xl);

  @media (max-height: 350px) {
    height: fit-content !important;
  }
`;

export const CameraPlaceholder = styled.div`
  max-width: 31.25rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md);
`;

export const CameraPlaceholderText = styled(RMText)`
  white-space: pre-line;
`;

export const AllowInstructions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md);

  &[data-safari='true'],
  body[data-mobile='true'] & {
    margin-top: auto;
  }
`;

export const LoadingSpinnerAnimation = styled(LottieAnimation)`
  position: relative;
  width: 6rem;
  height: 6rem;
  flex: 0 0 6rem;
`;

export const AskingPermissionSpinnerAnimation = styled(LoadingSpinnerAnimation)`
  body[data-mobile='true'] & {
    margin-top: auto;
    padding-top: 15px;
  }
`;

export const ArrowAnimation = styled(LottieAnimation)`
  position: relative;
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;
  transform: rotate(180deg);
`;
