import { styled } from '@linaria/react';

export const StyledRecordingPreview = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);

  width: 100%;
  height: 100%;
  max-height: 343px;
  border-radius: var(--radius-smooth);
  margin: auto 0;

  body[data-mobile='false'] & {
    margin: auto 0;
    max-height: 536px;
    gap: var(--spacing-xl);
  }

  &[data-recording-type='audio'] {
    background: var(--surface-dim);
    padding: var(--spacing-md);
    border: 1px solid var(--border-weak);
  }
`;

export const VideoWrapper = styled.div`
  height: 100%;

  > video {
    width: 100%;
    height: 100%;
    max-width: 536px;
    max-height: 536px;

    body[data-mobile='true'] & {
      max-width: 343px;
      max-height: 343px;
    }
  }
`;
