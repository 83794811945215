import arrowAnimationData from '@/@assets/arrow-down-lottie-animation.json';
import CamPermIcon from '@/assets/icons/cam-perm.svg';
import MicPermIcon from '@/assets/icons/mic-perm.svg';
import spinnerAnimationData from '@/assets/spinner.lottie.json';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { isBrowser } from '@/hooks/useIsBrowser';
import { useIsDesktopViewport, useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { RecordingLayout } from '../../layouts/RecordingLayout';
import { BrowserNotSupportedModal } from '../BrowserNotSupportedModal/BrowserNotSupportedModal';

import {
  AllowInstructions,
  ArrowAnimation,
  AskingPermissionSpinnerAnimation,
  CameraPlaceholder,
  CameraPlaceholderText,
  Content,
  LoadingContent,
  LoadingSpinnerAnimation,
} from './RecordPermission.styles';

export interface RecordPermissionProps {
  type: 'audio' | 'video';
  link: string;
  askingPermission: boolean;
  loadingPermission: boolean;
  browserNotSupported: boolean;
  onRequestPermissions: () => void;
  onBack: () => void;
}

export function RecordPermission({
  type,
  link,
  askingPermission = false,
  loadingPermission = false,
  browserNotSupported,
  onRequestPermissions,
  onBack,
}: RecordPermissionProps) {
  const isDesktop = useIsDesktopViewport();
  const isMobile = useIsMobileViewport();
  const isSafari = isBrowser('safari');

  return (
    <RecordingLayout.Root
      showLogo={isDesktop}
      centered
      Header={
        <RecordingLayout.Header
          step="setup"
          title={type === 'video' ? 'Camera setup' : 'Microphone setup'}
          onBack={onBack}
        />
      }
      Footer={
        !askingPermission ? (
          <RecordingLayout.Footer>
            <RMButton
              background="primary"
              fullWidth
              size={isDesktop ? 'massive' : 'medium'}
              minWidth="none"
              onClick={onRequestPermissions}
            >
              {type === 'audio' ? 'Turn on microphone' : 'Turn on camera'}
            </RMButton>
          </RecordingLayout.Footer>
        ) : null
      }
    >
      <Content data-loading={loadingPermission || askingPermission}>
        {loadingPermission ? (
          <LoadingContent>
            <LoadingSpinnerAnimation animationData={spinnerAnimationData} loop />
          </LoadingContent>
        ) : askingPermission ? (
          <LoadingContent>
            <AskingPermissionSpinnerAnimation data-asking-permission={true} animationData={spinnerAnimationData} loop />
            <AllowInstructions data-safari={isSafari}>
              {(isMobile || isSafari) && <ArrowAnimation animationData={arrowAnimationData} loop />}
              <RMText align="center" size={isMobile ? 'm' : 's'} type="sans" color="on-surface-primary">
                Press “
                <RMText size={isMobile ? 'm' : 's'} bold type="sans" color="on-surface-primary">
                  Allow
                </RMText>
                ” to turn on your {type === 'audio' ? 'microphone' : 'camera'}.
              </RMText>
            </AllowInstructions>
          </LoadingContent>
        ) : (
          <>
            <CameraPlaceholder>
              <img src={type === 'audio' ? MicPermIcon : CamPermIcon} alt="" />
              <RMText align="center" size={isMobile ? 'l' : 'xl'} type="serif" color="on-surface-primary">
                {type === 'audio' && 'Please allow microphone access on your device'}
                {type === 'video' && 'Please allow camera access on your device'}
              </RMText>
              <CameraPlaceholderText align="center" size="s" type="sans" color="on-surface-primary">
                {type === 'audio' && 'To record your stories, we need temporary access to your microphone.'}
                {type === 'video' && 'To record your stories, we need temporary access to your camera and microphone.'}
              </CameraPlaceholderText>
            </CameraPlaceholder>
          </>
        )}
      </Content>

      <BrowserNotSupportedModal open={browserNotSupported} link={link} />
    </RecordingLayout.Root>
  );
}
