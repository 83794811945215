import { useNavigate, useParams } from 'react-router-dom';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { RecordingLayout } from '@/modules/recording/layouts/RecordingLayout';
import { getProjectRecordPath, getStoryViewPath, RementoPage } from '@/modules/routing';
import { usePromptStoryQuery } from '@/services/api/story';

import { Buttons } from './RecordingPromptRecordedPage.styles';

function InternalRecordingPromptRecordedPage() {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params.projectId ?? '';
  const promptId = params.promptId ?? '';

  const storyQuery = usePromptStoryQuery(promptId);

  return (
    <RecordingLayout.Root dark Header={<RecordingLayout.Header step="hidden" hideSeparator />}>
      <RMDialog.Root open>
        <RMDialog.Content>
          <RMDialog.Header title="This story has been recorded" />
          <RMDialog.Body>
            <RMText type="sans" size="s" color="on-surface-primary">
              Looks like this prompt has already been answered.
            </RMText>
          </RMDialog.Body>
          <RMDialog.Footer>
            <Buttons>
              <RMButton
                onClick={() => {
                  navigate(getStoryViewPath(projectId, storyQuery.data?.id ?? ''));
                }}
              >
                View story
              </RMButton>
              <RMButton
                background="primary"
                onClick={() => {
                  navigate(
                    getProjectRecordPath(
                      projectId,
                      'prompt-already-recorded',
                      new URLSearchParams({ 'referrer-prompt-id': promptId }),
                    ),
                  );
                }}
              >
                Record another story
              </RMButton>
            </Buttons>
          </RMDialog.Footer>
        </RMDialog.Content>
      </RMDialog.Root>
    </RecordingLayout.Root>
  );
}

export function RecordingPromptRecordedPage() {
  return (
    <RementoPage type="empty">
      <InternalRecordingPromptRecordedPage />
    </RementoPage>
  );
}
