import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { logger } from '@/logger';
import {
  MINIMUM_RECORDING_DURATION,
  useInterviewManager,
  useObserveRecordingInterruptedError,
  usePromptRecordings,
  useResetInterviewManager,
} from '@/modules/conversation-recorder/interview';
import { InterviewManagerTickerProvider } from '@/modules/conversation-recorder/interview/interview-manager-ticker';
import { InterviewSession } from '@/modules/conversation-recorder/interview-session';
import {
  RecordingControls,
  RecordingControlsAction,
  RecordingControlsState,
} from '@/modules/recording/components/RecordingControls/RecordingControls.tsx';
import { RecordingPreview } from '@/modules/recording/components/RecordingPreview/RecordingPreview.tsx';
import { useServices } from '@/Services';
import { Colors } from '@/styles/base/colors.ts';
import { captureException } from '@/utils/captureException';

import { RecordingStep } from '../components/RecordingStep/RecordingStep';

import { RecordingBannerContainer } from './RecordingBanner.container';
import { RecordingIndicatorContainer } from './RecordingIndicator.container';
import { RecordingPromptContainer } from './RecordingPrompt.container';

export interface RecordingStepContainerProps {
  promptId: string;
  type: 'audio' | 'video';
  persona: 'recipient' | 'sender';
  mediaStream: MediaStream;
  resumingSession: InterviewSession | null;
  onFinish: (duration: number) => void;
  onBack: () => void;
}

export function RecordingStepContainer({
  promptId,
  type,
  persona,
  mediaStream,
  resumingSession,
  onFinish,
  onBack,
}: RecordingStepContainerProps) {
  const isMobile = useIsMobileViewport();
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('referrer');

  // Services
  const { storytellingAnalyticsService } = useServices();

  // Recording
  const resetInterviewManager = useResetInterviewManager();
  const interviewManager = useInterviewManager();
  const [controlsState, setControlsState] = useState<RecordingControlsState>(() => ({
    type: 'ready',
    isFirstTake: true,
  }));
  const latestPromptRecordings = usePromptRecordings();

  // Callbacks
  const handleStartRecording = useCallback(async () => {
    await interviewManager.startRecording();
    storytellingAnalyticsService.onStorytellingRecordingStarted(persona, type, referrer);
  }, [interviewManager, persona, storytellingAnalyticsService, type, referrer]);

  const handleStopRecording = useCallback(async () => {
    try {
      const { duration } = await interviewManager.pauseRecording();
      storytellingAnalyticsService.onStorytellingRecordingEnded(persona, type, duration, referrer);
      return { duration };
    } catch (error) {
      captureException(error, true);
      return { duration: 0 };
    }
  }, [interviewManager, persona, storytellingAnalyticsService, type, referrer]);

  const handleRewindPrompt = useCallback(async () => {
    try {
      await interviewManager.retakePrompt();
      storytellingAnalyticsService.onStorytellingRestarted(persona, type, referrer);
    } catch (error) {
      captureException(error, true);
    }
  }, [interviewManager, persona, storytellingAnalyticsService, type, referrer]);

  const handleEndRecording = useCallback(async () => {
    try {
      const { duration } = await interviewManager.endRecording();
      onFinish(duration);
      storytellingAnalyticsService.onStorytellingRecordingFinished(persona, type, duration, referrer);
    } catch (error) {
      captureException(error, true);
    }
  }, [interviewManager, onFinish, persona, storytellingAnalyticsService, type, referrer]);

  const handleRetakePrompt = useCallback(async () => {
    try {
      await handleRewindPrompt();
      setControlsState({ type: 'ready', isFirstTake: false });
    } catch (error) {
      captureException(error, true);
      toast('Failed to rewind', 'dialog-panel-toast', 'error');
    }
  }, [handleRewindPrompt]);

  const handleRetakeCancel = useCallback(() => {
    if (controlsState.type !== 'review') {
      logger.error('INVALID_WIDGET_STATE_TO_RETAKE', controlsState);
      return;
    }
    setControlsState({ ...controlsState, isRetakeConfirmationOpen: false });
  }, [controlsState]);

  const handleWidgetAction = useCallback(
    async (action: RecordingControlsAction) => {
      switch (action) {
        case 'start-recording': {
          try {
            await handleStartRecording();
            setControlsState({ type: 'recording' });
          } catch (error) {
            captureException(error, true);
            setRecordingInterrupted(true);
          }
          break;
        }
        case 'stop-recording': {
          const { duration } = await handleStopRecording();
          setControlsState({
            type: 'paused',
            isRecordingTooShort: duration < MINIMUM_RECORDING_DURATION,
            canContinueRecording: duration < interviewManager.getMaxRecordingDuration(),
          });
          break;
        }
        case 'rewind-prompt': {
          if (controlsState.type !== 'review') {
            logger.error('INVALID_WIDGET_STATE_TO_RETAKE', controlsState);
            return;
          }
          setControlsState({ ...controlsState, isRetakeConfirmationOpen: true });
          break;
        }
        case 'confirm-recording': {
          if (controlsState.type !== 'paused') {
            logger.error('INVALID_WIDGET_STATE_TO_CONFIRM', controlsState);
            return;
          }

          setControlsState({
            type: 'review',
            isRetakeConfirmationOpen: false,
            canContinueRecording: controlsState.canContinueRecording,
          });
          storytellingAnalyticsService.onRecordingReviewArrived();
          break;
        }
        case 'close-confirm': {
          if (controlsState.type !== 'review') {
            logger.error('INVALID_WIDGET_STATE_TO_CLOSE_CONFIRM', controlsState);
            return;
          }

          setControlsState({
            type: 'paused',
            isRecordingTooShort: false,
            canContinueRecording: controlsState.canContinueRecording,
          });
          break;
        }
        case 'end-recording': {
          await handleEndRecording();
          setControlsState({ type: 'finished' });
          break;
        }
      }
    },
    [
      handleStartRecording,
      handleStopRecording,
      interviewManager,
      controlsState,
      storytellingAnalyticsService,
      handleEndRecording,
    ],
  );

  const handleBack = useCallback(async () => {
    switch (controlsState.type) {
      case 'ready': {
        await resetInterviewManager();
        onBack();
        break;
      }
      case 'review': {
        await handleWidgetAction('close-confirm');
        break;
      }
      default: {
        logger.warn('INVALID_CONTROLS_STATE_TO_GO_BACK', controlsState);
      }
    }
  }, [controlsState, handleWidgetAction, onBack, resetInterviewManager]);

  // Show the interrupted modal if an error happens
  const [recordingInterrupted, setRecordingInterrupted] = useState(false);
  useObserveRecordingInterruptedError(
    useCallback(
      (error) => {
        setRecordingInterrupted(true);
        setControlsState({ type: 'ready', isFirstTake: false });

        switch (error.reason) {
          case 'no-data-received':
            storytellingAnalyticsService.onStorytellingRecordingNoDataError(persona, type, referrer);
            break;
          case 'recorder-interrupted':
            storytellingAnalyticsService.onStorytellingRecordingInterruptedError(persona, type, referrer);
            break;
          case 'queue-disconnected':
            storytellingAnalyticsService.onStorytellingRecordingQueueDisconnectedError(persona, type, referrer);
            break;
        }
      },
      [persona, storytellingAnalyticsService, type, referrer],
    ),
  );

  // Resuming session
  useEffect(() => {
    if (resumingSession == null) {
      return;
    }

    setControlsState({
      type: 'paused',
      isRecordingTooShort: false,
      canContinueRecording: resumingSession.duration / 1000 < interviewManager.getMaxRecordingDuration(),
    });
  }, [interviewManager, resumingSession]);

  // Check if tab is active, if not, pause the recording
  const onVisibilityChange = useCallback(() => {
    if (controlsState.type !== 'recording') {
      return;
    }

    if (document.visibilityState === 'hidden') {
      logger.warn('RECORDING.AUTO_PAUSE', {
        reason: 'document-hidden',
      });
      handleWidgetAction('stop-recording');
    }
  }, [handleWidgetAction, controlsState.type]);

  // Check if tab is active, if not, pause the recording
  const onCloseTab = useCallback(
    async (event: BeforeUnloadEvent) => {
      if (controlsState.type !== 'recording') {
        return;
      }

      const confirmationMessage = 'This action will erase your current recording. Are you sure you want to proceed?';

      handleWidgetAction('stop-recording');
      event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    },
    [handleWidgetAction, controlsState.type],
  );

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);
    window.addEventListener('beforeunload', onCloseTab);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
      window.removeEventListener('beforeunload', onCloseTab);
    };
  }, [onCloseTab, onVisibilityChange]);

  return (
    <InterviewManagerTickerProvider>
      <Helmet>{controlsState.type === 'recording' && <meta name="theme-color" content={Colors.spruce[10]} />}</Helmet>

      <RecordingStep
        state={controlsState}
        onBack={handleBack}
        Banner={
          <RecordingBannerContainer
            onRecordTimeout={() => handleWidgetAction('stop-recording')}
            hideBanner={controlsState.type === 'ready' || controlsState.type === 'review'}
          />
        }
        RecordingIndicator={<RecordingIndicatorContainer state={controlsState.type} audioStream={mediaStream} />}
        RecordingPrompt={
          <RecordingPromptContainer promptId={promptId} fullscreenEnabled={controlsState.type !== 'recording'} />
        }
        RecordingPreview={<RecordingPreview recordings={latestPromptRecordings} recordingType={type} />}
        RecordingControls={
          <RecordingControls
            state={controlsState}
            mediaStream={mediaStream}
            recordingType={type}
            onAction={handleWidgetAction}
          />
        }
      />
      <RMConfirmationModal
        open={controlsState.type === 'paused' && controlsState.isRecordingTooShort}
        title="Your recording is a bit too short."
        message={`Recordings must be at least ${MINIMUM_RECORDING_DURATION} seconds long.`}
        confirmLabel="Start over"
        onConfirm={handleRetakePrompt}
        cancelLabel={null}
        btnFullWidth={isMobile}
        onClose={null}
      />
      <RMConfirmationModal
        open={controlsState.type === 'review' && controlsState.isRetakeConfirmationOpen}
        title="Heads up!"
        message="This action will erase your current recording. Are you sure you want to proceed?"
        type="danger"
        confirmLabel="Retake"
        onConfirm={handleRetakePrompt}
        onCancel={handleRetakeCancel}
      />

      <RMDialog.Root open={recordingInterrupted}>
        <RMDialog.Content>
          <RMDialog.Header title={`${type === 'video' ? 'Video' : 'Audio'} connection lost`} />
          <RMDialog.Body>
            <RMText type="sans" size="s" color="on-surface-primary">
              Unfortunately, your {type === 'video' ? 'video' : 'audio'} connection was lost due to a technical error.
              Please try recording again. If the issue persists, please contact{' '}
              <a href="mailto:support@remento.co">
                <RMText type="sans" size="s" color="primary" underline>
                  support@remento.co
                </RMText>
              </a>
              .
            </RMText>
          </RMDialog.Body>
          <RMDialog.Footer>
            <RMButton background="primary" onClick={() => window.location.reload()} fullWidth>
              Restart recording
            </RMButton>
          </RMDialog.Footer>
        </RMDialog.Content>
      </RMDialog.Root>
    </InterviewManagerTickerProvider>
  );
}
