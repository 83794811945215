import { styled } from '@linaria/react';

export const StyledRecordingIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-md);
  height: 40px;
  width: 100%;
  margin-top: var(--spacing-md);

  &[data-centered='true'] {
    justify-content: center;
  }
`;

export const Badge = styled.div`
  display: flex;
  gap: var(--spacing-xl);
  padding: var(--spacing-2xs) var(--spacing-md);
  border: 1px solid var(--border-hairline);
  border-radius: var(--radius-full);
`;

export const BadgeStatus = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);

  svg {
    width: 0.5rem;
    height: 0.5rem;
    transform: translateY(1px);
    color: var(--recording);
  }
`;
