import { styled } from '@linaria/react';

export const StyledRecordingControls = styled.div<{ disabled: boolean }>`
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-flow: row;
  gap: var(--spacing-md);
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  touch-action: none;

  body[data-mobile='false'][data-tablet='false'] & {
    height: 80px;
  }
`;

export const Pip = styled.div`
  height: 48px;
  width: 48px;
  flex: 0 0 48px;

  body[data-mobile='false'][data-tablet='false'] & {
    height: 80px;
    width: 80px;
    flex: 0 0 80px;
  }
`;

export const StartButtonWrapper = styled.div`
  position: relative;
  width: 100%;
`;

// export const StartArrowLottie = styled.div`
//   position: absolute;
//   width: 2.75rem;
//
//   right: calc(50%);
//   transform: translateX(50%);
//
//   // calculation lottie stay above the button and with correct margin
//   top: calc((2.75rem + var(--spacing-sm)) * -1);
//
//   body[data-mobile='true'] & {
//     width: 2rem;
//     top: calc((2rem + var(--spacing-sm)) * -1);
//   }
// `;
