import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';

import CountDownSound from '@/assets/sounds/countdown-321.wav';
import BeginSound from '@/assets/sounds/countdown-begin.wav';
import { RMText } from '@/components/RMText/RMText';

import { Backdrop, Countdown, SkipButton } from './RecordingCountdown.styles';

interface RecordingCountdownProps {
  onStartRecording: () => void;
}

function RecordingCountdownContent({ onStartRecording }: RecordingCountdownProps) {
  const [countdown, setCountdown] = useState(3);
  const [started, setStarted] = useState(false);
  const countdownIntervalHandle = useRef<number | null>(null);
  const countdownDelayHandle = useRef<number | null>(null);

  const onStartRecordingRef = useRef<() => void>();

  // The onStartRecordingRef keeps a handle of the latest value of onStartRecording that was provided to the component.
  // It allows the useEffect responsible for the countdown to access the latest value of onStartRecording without running again.
  useLayoutEffect(() => {
    onStartRecordingRef.current = onStartRecording;
  }, [onStartRecording]);

  const countdownSFX = useMemo(() => new Audio(CountDownSound), []);
  const beginSFX = useMemo(() => new Audio(BeginSound), []);

  const handleEndCountdown = useCallback(() => {
    if (countdownIntervalHandle.current) {
      window.clearInterval(countdownIntervalHandle.current);
      countdownIntervalHandle.current = null;
      onStartRecordingRef.current?.();
    }

    if (countdownDelayHandle.current) {
      window.clearTimeout(countdownDelayHandle.current);
      countdownDelayHandle.current = null;
    }
  }, []);

  const countdownFormatted = Math.floor(Math.max(countdown, 0));

  // Animation tick should be half of second to allow the number start to fade out and the next one fade in
  const animationTick = 1;
  const startDelay = 0.5;
  const beginDuration = 1;
  const fadeScreenDuration = 0.5;

  useEffect(() => {
    countdownDelayHandle.current = window.setTimeout(() => {
      countdownSFX.play();
      setStarted(true);
      countdownIntervalHandle.current = window.setInterval(() => {
        setCountdown((previous) => {
          const next = previous - animationTick;
          console.log(next);

          if (next <= -beginDuration) {
            handleEndCountdown();
            return 0;
          }

          if (next > 0) {
            countdownSFX.play();
          } else if (next === 0) {
            beginSFX.play();
          }

          return next;
        });
      }, animationTick * 1000);
    }, startDelay * 1000);

    return () => {
      if (countdownDelayHandle.current) {
        window.clearTimeout(countdownDelayHandle.current);
      }
    };
  }, [handleEndCountdown, countdownSFX, beginSFX]);

  return (
    <AnimatePresence>
      {countdown > -beginDuration && (
        <Backdrop
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: fadeScreenDuration, ease: 'easeIn' } }}
          exit={{ opacity: 0, transition: { duration: fadeScreenDuration, ease: 'easeOut' } }}
          data-begin={countdownFormatted <= 0}
        >
          {/* Just an empty div for flex purposes (countdown centered and skip button at the bottom) */}
          <div />

          <Countdown key={`${countdownFormatted}`} data-done={countdownFormatted <= 0}>
            {started ? (countdownFormatted <= 0 ? 'Begin' : countdownFormatted) : ''}
          </Countdown>

          <SkipButton onClick={handleEndCountdown}>
            <RMText
              style={{ opacity: countdownFormatted > 0 ? 1 : 0 }}
              type="sans"
              size="m"
              bold
              color="inverse-primary"
            >
              Skip
            </RMText>
          </SkipButton>
        </Backdrop>
      )}
    </AnimatePresence>
  );
}

export function RecorderCountdown(props: RecordingCountdownProps) {
  const portalRoot = document.getElementById('dialog');
  if (!portalRoot) {
    console.error('Missing dialog root for dialog');
    return null;
  }

  return createPortal(<RecordingCountdownContent {...props} />, portalRoot);
}
