import { useCallback } from 'react';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { useIsDesktopViewport } from '@/hooks/useIsMobileViewport.ts';

import { RecordWaveformContainer } from '../../containers/RecordWaveform.container';
import { RecordingLayout } from '../../layouts/RecordingLayout';

import {
  CenteredText,
  Content,
  Footer,
  HeaderWaveformWrapper,
  Video,
  VideoContainer,
  WaveformWrapper,
} from './RecordReady.styles';

export interface RecordReadyProps {
  type: 'audio' | 'video';
  mediaStream: MediaStream;
  onContinue: () => void;
  onOpenDevicesModal: () => void;
  onBack: () => void;
}

export function RecordReady({ type, mediaStream, onContinue, onOpenDevicesModal, onBack }: RecordReadyProps) {
  const isDesktop = useIsDesktopViewport();

  const videoRef = useCallback(
    (videoElement: HTMLVideoElement | null) => {
      if (!videoElement) {
        return;
      }

      videoElement.srcObject = mediaStream;
      // We're doing this instead of using the `autoplay` prop cause iOS (Webkit) displays a play/pause button
      // on top of the video when in Low Power Mode. Check: https://stackoverflow.com/a/69709669
      videoElement.play();
    },
    [mediaStream],
  );

  return (
    <RecordingLayout.Root
      showLogo={isDesktop}
      Header={
        <RecordingLayout.Header
          step="setup"
          title={type === 'video' ? 'Camera and sound test' : 'Sound test'}
          onBack={onBack}
          onChangeSettings={onOpenDevicesModal}
        >
          <HeaderWaveformWrapper>
            {type === 'video' && <RecordWaveformContainer size="small" stream={mediaStream} />}
          </HeaderWaveformWrapper>
        </RecordingLayout.Header>
      }
      Footer={
        <RecordingLayout.Footer>
          <RMButton
            rightIcon={faArrowRight}
            background="primary"
            fullWidth
            size={isDesktop ? 'massive' : 'medium'}
            minWidth="none"
            autoLoading
            onClick={onContinue}
          >
            Next step
          </RMButton>
        </RecordingLayout.Footer>
      }
    >
      <Content>
        {type === 'audio' && (
          <WaveformWrapper>
            <RecordWaveformContainer size="large" stream={mediaStream} />
            <CenteredText type="sans" size="s" color="on-surface-primary">
              Line not moving when you speak? Tap the gear in the top right corner to troubleshoot.
            </CenteredText>
          </WaveformWrapper>
        )}
        {type === 'video' && (
          <VideoContainer>
            <Video muted playsInline ref={videoRef} />
          </VideoContainer>
        )}
        <Footer>
          <RMText type="sans" size="s" color="on-surface-primary" align="center">
            {type === 'audio' && 'Check that the microphone bar moves with your voice.'}
            {type === 'video' && 'Check that you can see yourself and the microphone bar moves with your voice.'}
          </RMText>
        </Footer>
      </Content>
    </RecordingLayout.Root>
  );
}
