import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { DelayedText, DelayedTextEntry, EmptyLine } from '@/components/DelayedText/DelayedText';
import { RMButton } from '@/components/RMButton/RMButton';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { BrowserNotSupportedModal } from '@/modules/recording/components/BrowserNotSupportedModal/BrowserNotSupportedModal';
import { RecordingIntroLoader } from '@/modules/recording/components/RecordingIntroLoader/RecordingIntroLoader.tsx';
import { RecordingLayout } from '@/modules/recording/layouts/RecordingLayout';
import { getRecordingTypeSelectionPath, RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { useCurrentUrlWithTokens } from '@/services/api/authorization';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery } from '@/services/api/project';
import { hasIndexedDbSupport } from '@/utils/hasIndexedDbSuport';

import {
  ANIMATION_PROPS,
  Body,
  BodyContent,
  BodyContentWrapper,
  Footer,
  FooterButtonsWrapper,
  GreetingMessage,
  LearnMoreVideo,
} from './RecordingIntroPage.styles.js';

const LEARN_MORE_VIDEO_BUCKET = 'https://storage.googleapis.com/remento-infrastructure-cdn/website/webapp-remento-co';
const LEARN_MORE_VIDEO_SRC = LEARN_MORE_VIDEO_BUCKET + '/recording-learn-more-desktop.mp4';
const LEARN_MORE_VIDEO_SRC_MOBILE = LEARN_MORE_VIDEO_BUCKET + '/recording-learn-more-mobile.mp4';

function RecordingIntro() {
  const { storytellingAnalyticsService, recordingSessionRepository } = useServices();

  const isMobile = useIsMobileViewport();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('referrer');

  const [step, setStep] = useState<'greeting' | 'welcome'>('greeting');

  const user = useUser();
  const projectQuery = useProjectQuery(params.projectId ?? null);
  const subjectPersonId = projectQuery.data?.subjectPersonIds?.[0];
  const isFirstTimeRecording = useMemo(() => {
    if (user === undefined || projectQuery.data === undefined) {
      return undefined;
    }

    // We will only update the onboarding history and the project statistics after processing the recording.
    // So if the user is recording one more than one time in the first session, we should avoid showing
    // the first intro twice. We can check the session repository to check if the user already recorded
    // in this session.
    if (recordingSessionRepository.getTotalProjectRecordingTime() !== null) {
      return false;
    }

    if (user !== null) {
      const alreadyRecorded = user.onboardingHistory.firstRecordingDone?.done ?? false;
      return !alreadyRecorded;
    }

    return (projectQuery.data.statistics.storiesRecorded ?? 0) === 0;
  }, [projectQuery.data, recordingSessionRepository, user]);

  const recorderPersonId = searchParams.get('recorder-person-id') ?? user?.personId;
  const recorderPersonQuery = usePersonQuery(recorderPersonId);

  // Learn more video
  const learnMoreVideoSrc = isMobile ? LEARN_MORE_VIDEO_SRC_MOBILE : LEARN_MORE_VIDEO_SRC;
  const [learnMoreVideoOpen, setLearnMoreVideoOpen] = useState(false);
  const [learnMoreVideoEnded, setLearnMoreVideoEnded] = useState(false);
  const learnMoreVideoRef = useRef<HTMLVideoElement | null>(null);

  // Callbacks
  const handleStartRecording = useCallback(() => {
    navigate(
      getRecordingTypeSelectionPath(params.projectId ?? '', params.promptId ?? '', 'prompt-review', searchParams),
    );
    storytellingAnalyticsService.onStorytellingResponseStarted(
      subjectPersonId === user?.personId ? 'sender' : 'recipient',
      referrer,
    );
  }, [navigate, params, searchParams, storytellingAnalyticsService, subjectPersonId, user?.personId, referrer]);

  const handleLearnMoreVideoEnded = useCallback(() => {
    setLearnMoreVideoEnded(true);
    storytellingAnalyticsService.onRecordingIntroVideoWatchedArrived(referrer);
  }, [referrer, storytellingAnalyticsService]);

  const handleWatchAgain = useCallback(() => {
    learnMoreVideoRef.current?.play();
  }, []);

  const handleLearnMore = useCallback(() => {
    setLearnMoreVideoOpen(true);
    storytellingAnalyticsService.onStorytellingLearnMorePressed(
      subjectPersonId === user?.personId ? 'sender' : 'recipient',
      referrer,
    );
  }, [subjectPersonId, storytellingAnalyticsService, user?.personId, referrer]);

  // Greeting texts
  const loadingText = useMemo(() => {
    return '. . . . . . .'.split(' ');
  }, []);

  const greetingText = useMemo<DelayedTextEntry>(() => {
    if (isFirstTimeRecording) {
      return ["Welcome to Remento! Your stories matter, and we're here to help you tell them.", EmptyLine];
    }

    const currentDayHour = new Date().getHours();
    let dayGreeting: string;
    if (currentDayHour >= 17 || currentDayHour < 3) {
      dayGreeting = 'Good evening';
    } else if (currentDayHour >= 3 && currentDayHour < 12) {
      dayGreeting = 'Good morning';
    } else {
      dayGreeting = 'Good afternoon';
    }

    if (recorderPersonQuery.data?.name?.first != null) {
      dayGreeting += `, ${recorderPersonQuery.data?.name?.first}`;
    }

    return `${dayGreeting}.`;
  }, [isFirstTimeRecording, recorderPersonQuery.data?.name?.first]);

  const welcomeText = useMemo<DelayedTextEntry>(() => {
    if (isFirstTimeRecording) {
      return 'Ready to begin?';
    }

    return 'It’s time to share your next story.';
  }, [isFirstTimeRecording]);

  // Analytics
  useEffect(() => {
    if (isFirstTimeRecording === undefined) {
      return;
    }

    storytellingAnalyticsService.onRecordingIntroArrived(isFirstTimeRecording, referrer);
  }, [isFirstTimeRecording, referrer, storytellingAnalyticsService]);

  // Check if is using incognito
  const [isIncognito, setIsIncognito] = useState<boolean>();
  const recordingLink = useCurrentUrlWithTokens();
  const isLoading = isIncognito === undefined || projectQuery.data == null;

  useEffect(() => {
    const checkIsIncognito = async () => {
      const supportsIndexedDb = await hasIndexedDbSupport();
      setIsIncognito(!supportsIndexedDb);
    };

    checkIsIncognito();
  }, []);

  if (isFirstTimeRecording === undefined) {
    return <RecordingIntroLoader />;
  }

  if (isIncognito) {
    return (
      <RecordingLayout.Root dark Header={<RecordingLayout.Header step="hidden" hideSeparator />}>
        <BrowserNotSupportedModal open link={recordingLink} />
      </RecordingLayout.Root>
    );
  }

  return (
    <RecordingLayout.Root
      dark
      showLogo
      animateLogo
      Footer={
        <RecordingLayout.Footer hideSeparator>
          <motion.div
            style={{ width: '100%' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: step === 'welcome' ? 1 : 0 }}
            transition={{ delay: 2, duration: 0.3 }}
          >
            <Footer data-direction={isFirstTimeRecording || learnMoreVideoOpen ? 'row' : 'column'}>
              <AnimatePresence>
                {!learnMoreVideoOpen && (
                  <FooterButtonsWrapper key="get-started-buttons" {...ANIMATION_PROPS} data-direction="column">
                    <RMButton
                      background="gradient"
                      size="massive"
                      fullWidth
                      onClick={isFirstTimeRecording ? handleLearnMore : handleStartRecording}
                    >
                      {isFirstTimeRecording ? 'Get started' : 'Continue'}
                    </RMButton>
                    {!isFirstTimeRecording && (
                      <RMButton color="secondary" background="transparent" fullWidth onClick={handleLearnMore}>
                        Learn more
                      </RMButton>
                    )}
                  </FooterButtonsWrapper>
                )}
                {learnMoreVideoOpen && !learnMoreVideoEnded && (
                  <FooterButtonsWrapper key="continue-buttons" {...ANIMATION_PROPS}>
                    <RMButton
                      background="transparent"
                      color="secondary"
                      size="massive"
                      fullWidth
                      onClick={handleStartRecording}
                    >
                      Skip video
                    </RMButton>
                  </FooterButtonsWrapper>
                )}{' '}
                {learnMoreVideoOpen && learnMoreVideoEnded && (
                  <FooterButtonsWrapper key="finished-video-buttons" {...ANIMATION_PROPS}>
                    <RMButton background="neutral" size="massive" fullWidth onClick={handleWatchAgain}>
                      Watch again
                    </RMButton>
                    <RMButton background="gradient" size="massive" fullWidth onClick={handleStartRecording}>
                      Next step
                    </RMButton>
                  </FooterButtonsWrapper>
                )}
              </AnimatePresence>
            </Footer>
          </motion.div>
        </RecordingLayout.Footer>
      }
    >
      <Body>
        <BodyContentWrapper>
          <AnimatePresence>
            {!learnMoreVideoOpen && (
              <BodyContent key="greeting-content" {...ANIMATION_PROPS}>
                <GreetingMessage>
                  {isLoading && <DelayedText text={loadingText} />}

                  {!isLoading && (step === 'greeting' || step === 'welcome') && (
                    <DelayedText
                      text={greetingText}
                      onAnimationEnd={() => setTimeout(() => setStep('welcome'), isFirstTimeRecording ? 0 : 500)}
                    />
                  )}

                  {step === 'welcome' && <DelayedText text={welcomeText} />}
                </GreetingMessage>
              </BodyContent>
            )}
            {learnMoreVideoOpen && (
              <BodyContent key="learn-more-content" {...ANIMATION_PROPS}>
                <LearnMoreVideo
                  ref={learnMoreVideoRef}
                  src={learnMoreVideoSrc}
                  controls
                  autoPlay
                  playsInline
                  disablePictureInPicture
                  onEnded={handleLearnMoreVideoEnded}
                  onPlay={() => setLearnMoreVideoEnded(false)}
                  onSeeked={() => setLearnMoreVideoEnded(false)}
                />
              </BodyContent>
            )}
          </AnimatePresence>
        </BodyContentWrapper>
      </Body>
    </RecordingLayout.Root>
  );
}

export function RecordingIntroPage() {
  return (
    <RementoPage type="record" loader={<RecordingIntroLoader />}>
      <RecordingIntro />
    </RementoPage>
  );
}
