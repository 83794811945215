import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { faMicrophone, faVideo } from '@fortawesome/pro-solid-svg-icons';
import { ACL_VIEW_ROLES } from '@remento/types/acl';
import { EntityType } from '@remento/types/entity';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';
import { useIsDesktopViewport } from '@/hooks/useIsMobileViewport';
import { useEnumQueryParam } from '@/hooks/useQueryParam';
import { useLastInterviewSessionsByPromptId } from '@/modules/conversation-recorder/interview';
import { RecordingPromptContainer } from '@/modules/recording/containers/RecordingPrompt.container';
import { RecordingStorytellerSelectionDialogContainer } from '@/modules/recording/containers/RecordingStorytellerSelectionDialog.container';
import { RecordingLayout } from '@/modules/recording/layouts/RecordingLayout';
import { getProjectRecordPath, getRecordingIntroPath, getRecordingPath, RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { hasRole, useCurrentUserAclRoles, usePrimaryAclGroup } from '@/services/api/acl';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery, usePromptQuery } from '@/services/api/project';

import {
  RecordingModeBodyWrapper,
  RecordingModeIcon,
  RecordingModeIconsWrapper,
  RecordingPromptBodyWrapper,
} from './RecordingTypeSelectionPage.styles';

interface RecordingTypeSelectionPageProps {
  projectId: string;
  promptId: string;
}

function RecordingTypeSelectionPage({ projectId, promptId }: RecordingTypeSelectionPageProps) {
  const isDesktop = useIsDesktopViewport();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const referrer = searchParams.get('referrer');

  // Services
  const { storytellingAnalyticsService } = useServices();
  const { lastSession } = useLastInterviewSessionsByPromptId(params.promptId ?? null);
  const [recordingStorytellerSelectorDialogOpen, setRecordingStorytellerSelectorDialogOpen] = useState(false);

  // Entities
  const user = useUser();
  const promptQuery = usePromptQuery(promptId);
  const projectQuery = useProjectQuery(projectId);
  const storytellerQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);
  const projectAcl = usePrimaryAclGroup(projectQuery.data?.acl, EntityType.PROJECT);
  const userProjectRoles = useCurrentUserAclRoles(projectAcl ? [projectAcl.id] : null);
  const canViewProject = hasRole(ACL_VIEW_ROLES, userProjectRoles ?? []);

  // Screen state
  const [pageStep, setPageStep] = useEnumQueryParam<'prompt-review' | 'type-selection'>('step', 'prompt-review', [
    'prompt-review',
    'type-selection',
  ]);

  // Callbacks
  const goToRecord = useCallback(
    (type: 'audio' | 'video') => {
      navigate(getRecordingPath({ projectId, promptId, type, customParams: searchParams }));
      storytellingAnalyticsService.onStorytellingInputSelected(
        user?.personId === promptQuery.data?.requesterPersonId ? 'sender' : 'recipient',
        type,
        referrer,
      );
    },
    [
      navigate,
      promptId,
      promptQuery.data?.requesterPersonId,
      searchParams,
      projectId,
      storytellingAnalyticsService,
      user?.personId,
      referrer,
    ],
  );

  const handleRecordingStorytellerSelectionDialogConfirm = useCallback(
    (recorderPersonId: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('recorder-person-id', recorderPersonId);

      setSearchParams(newSearchParams);
      setRecordingStorytellerSelectorDialogOpen(false);
    },
    [searchParams, setSearchParams],
  );

  const handleRecordAnotherPrompt = useCallback(() => {
    navigate(
      getProjectRecordPath(
        projectId,
        'recording-type-selection',
        new URLSearchParams({ 'referrer-prompt-id': promptId }),
      ),
    );
  }, [navigate, projectId, promptId]);

  const handlePromptReviewBack = useCallback(() => {
    if (searchParams.get('referrer') === 'project-record') {
      navigate(getProjectRecordPath(projectId, undefined, searchParams));
    } else {
      navigate(getRecordingIntroPath(projectId, promptId, searchParams));
    }
  }, [navigate, projectId, promptId, searchParams]);

  const handleTypeSelectionBack = useCallback(() => {
    setPageStep('prompt-review');
  }, [setPageStep]);

  // Open recorder select dialog if there's no selected recorder person
  useEffect(() => {
    const currentRecorderPersonId = searchParams.get('recorder-person-id');
    if (currentRecorderPersonId != null) {
      return;
    }

    if (user == null) {
      return;
    }

    if (!canViewProject) {
      return;
    }

    const isStoryteller = storytellerQuery.data == null || storytellerQuery.data.refIds.includes(user.personId);
    if (isStoryteller) {
      return;
    }

    setRecordingStorytellerSelectorDialogOpen(true);
  }, [searchParams, user, storytellerQuery, canViewProject]);

  // Navigate to recovery if there's already a session
  useEffect(() => {
    if (lastSession == null) {
      return;
    }

    navigate(getRecordingPath({ projectId, promptId, type: lastSession.recordingType, customParams: searchParams }));
  }, [lastSession, navigate, projectId, promptId, searchParams]);

  // Analytics
  useEffect(() => {
    switch (pageStep) {
      case 'prompt-review':
        storytellingAnalyticsService.onRecordingPromptReviewArrived();
        break;
      case 'type-selection':
        storytellingAnalyticsService.onRecordingTypeSelectionArrived();
        break;
    }
  }, [pageStep, storytellingAnalyticsService]);

  return (
    <RecordingLayout.Root
      showLogo={isDesktop}
      centered={pageStep === 'type-selection'}
      Header={
        <RecordingLayout.Header
          step="setup"
          title={pageStep === 'prompt-review' ? 'Your prompt' : 'Choose recording mode'}
          onBack={pageStep === 'prompt-review' ? handlePromptReviewBack : handleTypeSelectionBack}
        />
      }
      Footer={
        <RecordingLayout.Footer>
          {/*Prompt review*/}
          {pageStep === 'prompt-review' && (
            <>
              <RMButton
                background="neutral"
                fullWidth
                size={isDesktop ? 'massive' : 'medium'}
                minWidth="none"
                onClick={handleRecordAnotherPrompt}
              >
                Change prompt
              </RMButton>

              <RMButton
                rightIcon={faArrowRight}
                background="primary"
                fullWidth
                size={isDesktop ? 'massive' : 'medium'}
                minWidth="none"
                onClick={() => setPageStep('type-selection')}
              >
                Next step
              </RMButton>
            </>
          )}

          {/*Type Selection*/}
          {pageStep === 'type-selection' && (
            <>
              <RMButton
                leftIcon={faMicrophone}
                background="neutral"
                fullWidth
                size={isDesktop ? 'massive' : 'medium'}
                minWidth="none"
                onClick={() => goToRecord('audio')}
              >
                Audio
              </RMButton>

              <RMButton
                leftIcon={faVideo}
                background="primary"
                fullWidth
                size={isDesktop ? 'massive' : 'medium'}
                minWidth="none"
                onClick={() => goToRecord('video')}
              >
                Video
              </RMButton>
            </>
          )}
        </RecordingLayout.Footer>
      }
    >
      {/*Prompt review*/}
      {pageStep === 'prompt-review' && (
        <RecordingPromptBodyWrapper>
          <RecordingPromptContainer promptId={promptId} />
        </RecordingPromptBodyWrapper>
      )}

      {/*Type Selection*/}
      {pageStep === 'type-selection' && (
        <RecordingModeBodyWrapper>
          <RecordingModeIconsWrapper>
            <RecordingModeIcon icon={faMicrophone} size="5x" />
            <RecordingModeIcon icon={faVideo} size="5x" />
          </RecordingModeIconsWrapper>
          <RMText type="sans" size="s" color="on-surface-primary" align="center">
            You can record your voice with a video or choose audio-only.
          </RMText>
        </RecordingModeBodyWrapper>
      )}

      <RecordingStorytellerSelectionDialogContainer
        projectId={projectId}
        open={recordingStorytellerSelectorDialogOpen}
        onContinue={handleRecordingStorytellerSelectionDialogConfirm}
      />
    </RecordingLayout.Root>
  );
}

export function RecordingTypeSelectionPageContainer() {
  const params = useParams();

  return (
    <RementoPage type="record">
      <RecordingTypeSelectionPage projectId={params.projectId ?? ''} promptId={params.promptId ?? ''} />
    </RementoPage>
  );
}
