import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useServices } from '@/Services';
import { IS_DEV } from '@/utils/isDev';

import { InterviewSessionRepository } from '../interview-session';
import { IdbPartitionQueueRepository } from '../partition-queue/partition-queue.idb.repository';
import { RecordingSessionService } from '../recording-session/recording-session.types';

import { InterviewManager, InterviewPartitionQueueRecord } from './interview-manager';
import { InterviewManagerStateType } from './interview-manager.types';

const InterviewManagerContext = createContext<{
  interviewManager: InterviewManager;
  resetInterviewManager: () => Promise<void>;
} | null>(null);

function createInterviewManager(
  recordingSessionService: RecordingSessionService,
  interviewSessionRepository: InterviewSessionRepository,
): InterviewManager {
  return new InterviewManager(
    recordingSessionService,
    interviewSessionRepository,
    new IdbPartitionQueueRepository<InterviewPartitionQueueRecord>(),
    IS_DEV ? 5 * 60 : 30 * 60,
  );
}

export function InterviewManagerProvider({ children }: PropsWithChildren<unknown>) {
  const { recordingSessionService, interviewSessionRepository } = useServices();

  const [interviewManager, setInterviewManager] = useState(() =>
    createInterviewManager(recordingSessionService, interviewSessionRepository),
  );

  const resetInterviewManager = useCallback(async () => {
    const currentState = interviewManager.getState();
    if (currentState.type !== InterviewManagerStateType.Empty && currentState.sessionId != null) {
      await interviewManager.deleteSession(currentState.sessionId);
    }

    setInterviewManager(createInterviewManager(recordingSessionService, interviewSessionRepository));
  }, [interviewManager, interviewSessionRepository, recordingSessionService]);

  const contextValue = useMemo(() => {
    return {
      interviewManager,
      resetInterviewManager,
    };
  }, [interviewManager, resetInterviewManager]);

  useEffect(() => {
    return () => {
      // This check will avoid issues when running in dev mode (useEffect running twice)
      if (interviewManager.getState().type !== InterviewManagerStateType.Empty) {
        interviewManager.destroy();
      }
    };
  }, [interviewManager]);

  return <InterviewManagerContext.Provider value={contextValue}>{children}</InterviewManagerContext.Provider>;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useInterviewManager(): InterviewManager {
  const contextValue = useContext(InterviewManagerContext);
  if (!contextValue) {
    throw new Error('useInterviewManager must be used inside InterviewManagerProvider');
  }
  return contextValue.interviewManager;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useResetInterviewManager(): () => Promise<void> {
  const contextValue = useContext(InterviewManagerContext);
  if (!contextValue) {
    throw new Error('useInterviewManager must be used inside InterviewManagerProvider');
  }
  return contextValue.resetInterviewManager;
}
