import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const ANIMATION_PROPS = {
  initial: { opacity: 0, scale: 0.8 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8 },
  transition: { duration: 0.3 },
} as const;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  z-index: 1;
`;

export const BodyContentWrapper = styled.div`
  position: relative;
  flex: 1;
`;

export const BodyContent = styled(motion.div)`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  @media (max-height: 370px) {
    height: unset;
  }
`;

export const GreetingMessage = styled.div`
  min-height: 10rem;

  body[data-mobile='false'][data-tablet='false'] & {
    min-height: 15rem;
  }

  span {
    font-family: var(--font-serif);
    font-size: 1.9375rem;
    color: var(--on-primary);
  }
`;

export const LearnMoreVideo = styled(motion.video)`
  height: 100%;
  width: 100%;
  background-color: var(--surface-always-dark);
`;

export const Footer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column;
  gap: var(--spacing-md);
  /* Same height as a massive button. See RMButton.styles */
  height: 80px;

  &[data-direction='column'] {
    /* Same height as a massive and normal buttons, plus the gap. See RMButton.styles */
    height: calc(var(--spacing-md) + 80px + 48px);
  }
`;

export const FooterButtonsWrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  display: flex;
  gap: var(--spacing-md);

  &[data-direction='column'] {
    flex-direction: column;
  }
`;
